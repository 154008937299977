/*
 *   File : phone-input.js
 *   Author : https://evoqins.com
 *   Description : Input element
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from "react";
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';

// Components
import { PhoneCountrySelectModal } from "../Modal";

const CustomPhoneInput = (props) => {

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(props.value); //already entered number
    }, [props.value]);

    //input handle change
    function _handleChange(value) {
        setInputValue(value);
        props.handleChange(value);
    };

    // handle country select
    function _handleCountrySelect(country) {
        let country_code = "+" + getCountryCallingCode(country);
        props.handleChange("", country_code, country);
    };

    return (
        <div id="custom-phone-otp">
            {
                props.label &&
                <label className={`e-text-dark-charcoal e-font-weight-400 e-alt-font-poppins line-height-24px e-font-14 pb-2`}>
                    {props.label}
                    {
                        props.require &&
                        <span className='e-text-sunset-orange'>*</span>
                    }
                </label>
            }
            <div className="position-relative">
                <PhoneInput
                    defaultCountry={props.selectedCountryLocale || "IN"}
                    value={inputValue}
                    limitMaxLength
                    onChange={_handleChange}
                    disabled={props.disabled}
                    inputComponent={CustomInput}
                    numberInputProps={{ country_code: props.selectedCountryCode, disabled: props.disabled }}
                    onCountryChange={_handleCountrySelect}
                    countrySelectComponent={({ value, onChange }) => (
                        <PhoneCountrySelectModal value={value}
                            disableCountrySelect={props.disableCountrySelect}
                            handleCountrySelect={onChange} />
                    )}
                />
                {
                    props.textLink &&
                    <p className={`cursor-pointer e-text-egyptian-blue me-3 mb-0 position-absolute end-0 top-50 translate-middle-y e-font-14 e-font-weight-500 e-alt-font-poppins line-height-24px`}
                        onClick={props.handleSendOtp}>
                        {props.textLabel}
                    </p>
                }
            </div>

            <p className={`e-error-message e-font-12 e-font-weight-400 e-primary-font-inter-font h-20 line-height-30 e-text-red mb-0`}>
                {props.error}
            </p>
        </div >
    )
}

export default memo(CustomPhoneInput);


const CustomInput = React.forwardRef(({ value, onChange, country_code, disabled }, ref) => {

    // Removing country code
    let input_value = value;
    if (country_code === "+91") {
        input_value = input_value.replace(/^\+91\s?/, '').replace(/\s+/g, '');
    } else {
        input_value = input_value.replace(country_code, '').replace(/\s+/g, '').replace(/\D/g, '');
    }

    const _handleChange = (event) => {
        let value = event.target.value;
        if (country_code === "+91" && value.length < 11) {
            onChange(value);
        } else {
            onChange(value);
        }
    };

    return (
        <input
            disabled={disabled}
            ref={ref}
            placeholder="Enter phone number"
            value={input_value}
            onChange={_handleChange}
            className="h-100 w-100 border-0 no-outline"
        />
    )
})
