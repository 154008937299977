/*
 *   File : portfolio.js
 *   Author : https://evoqins.com
 *   Description : user portfolio card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect } from "react"

// Component
import { Icon } from "../Icon";
import { useState } from "react";


function PortfolioCard(props) {

    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    return (
        <div className="d-flex gap-4 flex-column p-4 e-portfolio-gradient e-border-radius-16 w-100">
            <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 e-font-18-res e-text-white e-alt-font-poppins e-font-16 e-font-weight-500">
                    Investment summary
                </p>
                {
                    props.type == 2 &&
                    <div className="form-check form-switch mb-0 d-flex align-items-center gap-2">
                        <input className="cursor-pointer form-check-input m-0 shadow-none"
                            checked={props.enableExternal}
                            type="checkbox"
                            role="switch"
                            id="external-portfolio"
                            onChange={props.onChangeInvestmentMethod}
                        />

                        <label className="form-check-label e-text-white e-alt-font-poppins e-font-14 e-font-weight-500">
                            External portfolio
                        </label>
                    </div>
                }
            </div>

            {/* investment */}
            <div className="d-flex gap-3  flex-wrap justify-content-between align-items-center">
                <div className="col-auto col-sm-5 ">
                    <p className="e-font-16-res mb-1 mb-sm-2 e-text-white e-alt-font-poppins e-font-14 e-font-weight-400">
                        Invested amount
                    </p>
                    <span className="e-font-20-res e-text-white e-primary-font-inter e-font-24 e-font-weight-600">
                        {data.invested_amount_text || "-"}
                    </span>
                </div>

                {/* Vertical line */}
                <span className="vr e-bg-white opacity-100 align-self-center h-40 d-none d-sm-flex"></span>

                <div className="col-auto col-sm-5">
                    <p className="e-font-16-res mb-1 mb-sm-2 e-text-white e-alt-font-poppins e-font-14 e-font-weight-400">
                        Current value
                    </p>
                    <span className="e-font-20-res e-text-white e-primary-font-inter e-font-24 e-font-weight-600">
                        {data.current_amount_text}
                    </span>
                </div>
            </div>

            {/* Returns */}
            <div className="d-flex gap-3 flex-column flex-sm-row flex-wrap justify-content-between align-items-center e-bg-white p-xl-4 p-lg-4 p-md-4 p-sm-4 p-2 e-border-radius-12 h-100">
                <div className="col-12 col-sm-5">
                    <div className="e-portfolio-returns d-flex gap-2 align-items-center mb-2">
                        <span className="e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-400 ">
                            Returns
                        </span>
                        <Icon icon={data.returns_amount >= 0 ? "positive-return-arrow" : "negative-return-arrow"}
                            size={"1.5rem"} />
                    </div>

                    <div>
                        <span className={`${data.returns_amount >= 0 ? "e-text-ufo-green" : "e-text-red"} e-font-20-res e-primary-font-inter e-font-24 e-font-weight-600 pe-1`}>
                            {data.returns_amount_text}
                        </span>
                        <span className={`${data.returns_percentage >= 0 ? "e-text-ufo-green" : "e-text-red"} e-font-16-res e-text-emerald e-primary-font-inter e-font-18 e-font-weight-600`}>
                            ({data.returns_percentage_text})
                        </span>
                    </div>
                </div>
                {
                    data.xirr_text &&
                    <>
                        {/* Verticle line */}
                        <span className="vr e-bg-lavender-gray opacity-100 align-self-center h-40 d-none d-sm-flex"></span>
                        <div className="col-12 col-sm-5">
                            <p className="e-portfolio-returns e-text-charleston-green e-alt-font-poppins e-font-14 e-font-weight-400 mb-2">
                                XIRR
                            </p>
                            <span className={`${data.xirr >= 0 ? "e-text-ufo-green" : "e-text-red"} e-font-20-res e-text-emerald e-primary-font-inter e-font-24 e-font-weight-600`}>
                                {data.xirr_text}
                            </span>
                        </div>
                    </>
                }
            </div>
        </div >
    );
};

export default memo(PortfolioCard);